import { Button, PillButton } from "@components/Button"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Carousel } from "@components/Carousel"
import { ClientList } from "@components/ClientList"
import { Layout } from "@components/Layout"
import { Arrow } from "@components/UI"
import BrandStrategyIcon from "@svg/next-gen-branding/brand-strategy.svg"
import ExecutionIcon from "@svg/next-gen-branding/execution.svg"
import RolloutIcon from "@svg/next-gen-branding/rollout.svg"
import React from "react"
/*global gtag*/
import { SalesForm } from "@components/Form"
import { Seo } from "@components/Seo"
import { usePageSetup } from "@components/usePageSetup"
import { splitText } from "@utils"
import { graphql } from "gatsby"

const NextGenBrandingPage = ({ data }) => {
  const { heroImage, chrisMilanImage, meetJKImage } = data
  const { themeColor } = usePageSetup({
    themeColor: "bg-gray-darkest",
    navigationColor: "text-white",
    displayGeneralForm: false,
  })

  const isBrowser = typeof window !== "undefined"

  const contactSubmission = () => {
    if (!isBrowser) {
      return
    }

    gtag("event", "contact_us_form_submission", { event_category: "contact_us", event_label: "next-gen-branding" })
  }

  return (
    <Layout>
      <Seo title="Next Gen Branding" description="" />
      <div className="overflow-hidden">
        <div className={themeColor} style={{ height: "94px" }}></div>
        <div className={themeColor}>
          <div className="container relative">
            <div className="absolute inset-x-0 bottom-0">
              <div className="aspect-w-326 aspect-h-93 sm:aspect-w-667 lg:aspect-w-1436 sm:aspect-h-78 extend before:bg-white-dark after:bg-white-dark"></div>
            </div>

            <div className="relative sg-hero-grid">
              <div className="mb-8 sg-title pt-14">
                <div>
                  <h1
                    className="mt-12 text-clamp-30-35"
                    data-aos="stagger"
                    dangerouslySetInnerHTML={{
                      __html: splitText({
                        "text-white":
                          "The next generation of family business owners is stepping up. Here’s how your company can become their trusted partner.",
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="pb-15 sm:pb-40 sg-message" data-aos="fade-up">
                <p className="text-white text-clamp-16-18">
                  <span className="font-bold text-teal-medium">
                    Nearly 90% of U.S. businesses
                    <sup>
                      <a
                        href="https://www.inc.com/encyclopedia/family-owned-businesses.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        1
                      </a>
                    </sup>
                    &mdash;including 50% of contractors
                    <sup>
                      <a
                        href="https://www.familybusinessinstitute.com/guidelines-for-successful-family-transition-in-construction-and-most-other-industries-too/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        2
                      </a>
                    </sup>
                    &mdash;are family-owned.
                  </span>{" "}
                  That includes everything from small companies to large corporations across every industry, with the
                  majority in manufacturing and construction/facilities.
                  <sup>
                    <a href="https://familyenterpriseusa.com/family-business-survey/" target="_blank" rel="noreferrer">
                      3
                    </a>
                  </sup>
                </p>
                <p className="text-white text-clamp-16-18">
                  Most want to keep control within the family once their current leaders step down.
                  <sup>
                    <a
                      href="https://www.pwc.com/us/en/services/trust-solutions/private-company-services/library/family-business-survey.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      4
                    </a>
                  </sup>{" "}
                  But the younger people taking over often have very different needs and priorities from the older
                  generation.
                </p>
                <div>
                  <Button className="mt-8 text-purple-light" link="#next-gen-branding-form">
                    Get in touch with an expert now
                  </Button>
                </div>
              </div>
              <div className="flex sg-image">
                <div className="grid self-end w-full h-full grid-cols-8 sm:grid-cols-6">
                  <div className="relative flex items-center col-span-7 sm:col-span-6 lg:col-span-5 lg:col-start-2">
                    <div className="pb-6 sm:pl-5 lg:pl-0 lg:pr-5 sm:pb-0">
                      <div className="relative" data-aos="fade-left">
                        <GatsbyImage objectFit="contain" image={getImage(heroImage)} alt="Next Gen Branding" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <main className="mt-8">
          <section>
            <div className="container">
              <div className="flex justify-center">
                <div className="sm:w-10/12">
                  <div className="w-full grid-cols-10 lg:grid">
                    <div data-aos="fade-right" className="col-start-1 col-end-5 pb-16 lg:pb-24">
                      <h2 className="font-bold text-clamp-26-32">
                        How can you win them over and become their supplier or partner of choice?
                      </h2>
                      <p className="mt-10 text-16">
                        Our recent blog post describes five ways these up-and-coming family business leaders differ from
                        their predecessors—and how your company can adapt to remain relevant and appealing to them. Read
                        it now for practical tips you can use to gain their trust and loyalty for the long haul.
                      </p>
                      <Button
                        external={true}
                        link="/ideas-and-insights/engage-the-next-generation-of-leaders"
                        className="mt-10 text-left text-blue"
                      >
                        5 ways to engage the next generation of family business leaders
                      </Button>
                    </div>
                    <div data-aos="fade-up" className="flex items-end col-start-6 col-end-11">
                      <div className="relative lg:top-8">
                        <GatsbyImage objectFit="contain" image={getImage(chrisMilanImage)} alt="Chris Milan" />
                      </div>
                    </div>
                  </div>
                  <div className="relative w-full grid-cols-10 lg:grid z-1">
                    <div className="col-start-4 col-end-10">
                      <div className="p-16 bg-blue-light">
                        <div data-aos="fade">
                          <PillButton staticButton={true} className="text-white bg-blue">
                            Our Thought Leader
                          </PillButton>
                          <h3 className="mt-5 font-bold text-white text-clamp-30-35">Chris Milan</h3>
                          <h4 className="font-bold text-clamp-18-22">Director of Branding &amp; Messaging</h4>
                          <p className="text-16 mt-7">
                            Chris is a skilled brand-builder and expert strategist who’s helped companies across a range
                            of industries create or adapt their brand stories for changing times. Find her on LinkedIn{" "}
                            <a href="https://www.linkedin.com/in/christinemilan/" target="_blank" rel="noreferrer">
                              @christinemilan
                            </a>
                            .
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="pt-clamp-10-30 pb-clamp-16-30">
            <div className="container">
              <div>
                <div data-aos="fade">
                  <PillButton staticButton={true} className="bg-purple-lighter">
                    Our Clients
                  </PillButton>
                </div>
                <h2
                  className="mt-10 text-clamp-35-45"
                  data-aos="stagger"
                  dangerouslySetInnerHTML={{
                    __html: splitText({
                      "text-gray-darkest": "Brand stories we’ve strengthened.",
                    }),
                  }}
                />
                <ClientList
                  className="fill-current text-gray-darkest gap-x-6 lg:gap-x-20 mt-clamp-9-8"
                  clients={[
                    "ad",
                    "amazon",
                    "amerlux",
                    "bestbuy",
                    "cat",
                    "dupont",
                    "hammond",
                    "j-and-j",
                    "netflix",
                    "merck",
                    "pepsico",
                    "philips",
                    "priceline",
                    "signify",
                    "spectrum",
                    "unilog",
                    "wb",
                    "zumtobel",
                  ]}
                />
              </div>
            </div>
          </section>

          <section className="mt-16 lg:mt-32 pb-clamp-22-40 bg-gray">
            <div className="container">
              <div className="flex flex-wrap lg:flex-row">
                <div className="w-full grid-cols-6 lg:grid lg:w-1/2">
                  <div data-aos="fade-right" className="col-span-5 py-clamp-22-40">
                    <h3 className="font-bold text-white text-clamp-30-45">Meet JK.</h3>
                    <p className="text-white text-clamp-18-22">
                      We’ll help you create, launch, and integrate your distinctive brand consistently across your
                      marketing and communications touchpoints.
                    </p>
                  </div>
                </div>
                <div className="w-full lg:w-1/2">
                  <div data-aos="fade-up" className="relative lg:px-6 lg:-top-24">
                    <GatsbyImage objectFit="contain" image={getImage(meetJKImage)} alt="Meet JK" />
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-clamp-12-24 lg:mt-0">
                <div className="flex flex-col w-full grid-cols-3 gap-8 lg:pl-6 sm:w-10/12 lg:gap-4 lg:w-full lg:grid mt-clamp-9-12">
                  <div className="flex flex-col gap-4 sm:flex-row" data-aos="fade-up">
                    <div>
                      <BrandStrategyIcon />
                    </div>
                    <div className="w-full lg:max-w-86">
                      <h3 className="font-bold text-20 text-teal-medium">Brand strategy and development</h3>
                      <p className="mt-4 text-white text-16">
                        We’ll help you build a cohesive brand identity and messaging that showcases your value and sets
                        you apart from your competition.
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 sm:flex-row" data-aos="fade-up" data-aos-delay={100}>
                    <div>
                      <RolloutIcon />
                    </div>
                    <div className="w-full lg:max-w-86">
                      <h3 className="font-bold text-20 text-teal-medium">Brand rollout</h3>
                      <p className="mt-4 text-white text-16">
                        We’ll help maximize the impact of your brand—engaging the right people at the right times and
                        places.
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 sm:flex-row" data-aos="fade-up" data-aos-delay={200}>
                    <div>
                      <ExecutionIcon />
                    </div>
                    <div className="w-full lg:max-w-86">
                      <h3 className="font-bold text-20 text-teal-medium">Brand execution</h3>
                      <p className="mt-4 text-white text-16">
                        We can bring your brand to life across every touchpoint, from your website to your social
                        platforms to your outreach and advertising campaigns.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

          <section className="pt-clamp-50-68 pb-clamp-27-43">
            <div className="container flex justify-center">
              <div data-aos="fade-up" className="w-full xl:w-10/12">
                <Carousel.Quote client="ad" single={true}></Carousel.Quote>
              </div>
            </div>
          </section>

          <div id="next-gen-branding-form" style={{ scrollMarginTop: "78px" }}></div>
          <section className="bg-purple-light pt-clamp-34-51 pb-clamp-27-40">
            <div className="container flex lg:justify-center">
              <div className="xl:w-10/12">
                <div className="grid-cols-10 lg:grid">
                  <div className="col-span-8">
                    <h2
                      data-aos="stagger"
                      className="text-title"
                      dangerouslySetInnerHTML={{
                        __html: splitText({
                          "text-white": "Let’s make your brand ",
                          break: "",
                          "text-gray-darkest": "as current and relevant as it can be",
                          "text-white-light": "to the next generation of business owners.",
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="grid-cols-10 lg:grid mt-clamp-7-11">
                  <div className="col-span-8 xl:col-span-7">
                    <p data-aos="fade-up">Please fill out the form below and we’ll be in touch soon.</p>
                  </div>
                </div>
              </div>
            </div>
            <section className="container mt-24">
              <div className="mx-auto xl:w-10/12">
                <SalesForm
                  id="632"
                  subject="JK Design - Sustainability"
                  recipient="katiek@jkdesign.com, markm@jkdesign.com"
                  fieldColor="bg-teal-light"
                  fn={contactSubmission}
                />
              </div>
            </section>
          </section>
        </main>
      </div>
    </Layout>
  )
}

export default NextGenBrandingPage

export const NextGenBrandingPageQuery = graphql`
  query nextGenBrandingPageQuery {
    heroImage: file(relativePath: { eq: "next-gen-branding/hero.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    chrisMilanImage: file(relativePath: { eq: "next-gen-branding/chris-milan.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    meetJKImage: file(relativePath: { eq: "next-gen-branding/meet-jk.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
